import { Separator } from "@casox/platform-ui-components-library";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

type LayoutProps = {
  children: React.ReactNode;
};

const AppLayout = ({ children }: LayoutProps) => {
  return (
    <div className="m-auto min-w-[700px] max-w-[1000px] h-screen p-3">
      <div className="px-8 flex flex-col h-full">
        <AppHeader />
        <Separator />
        <div className="py-4 flex-1 overflow-hidden overflow-y-auto">
          {children}
        </div>
        <Separator />
        <AppFooter />
      </div>
    </div>
  );
};

export default AppLayout;
