import { Configuration, PopupRequest } from "@azure/msal-browser";
import { MY_ADDRESS, PROCESS_ENVS } from "app/settings";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: PROCESS_ENVS.clientId,
    authority: PROCESS_ENVS.authority,
    redirectUri: MY_ADDRESS,
    postLogoutRedirectUri: MY_ADDRESS,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [PROCESS_ENVS.scopes],
};
