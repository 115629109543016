import { Button, Center } from "@casox/platform-ui-components-library";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "app/authConfig";
import LogoAzure from "components/LogoAzure";

const SignInButton = () => {
  const { instance } = useMsal();
  const handleRedirectLogin = () => {
    instance.loginRedirect(loginRequest);
  };
  return (
    <Button onClick={handleRedirectLogin}>
      <div className="flex">
        <div className="w-[50px] pr-4">
          <LogoAzure />
        </div>
        <div>
          <Center>Click to Login</Center>
        </div>
      </div>
    </Button>
  );
};

export default SignInButton;
