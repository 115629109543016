import { useNavigate, Routes, Route } from "react-router-dom";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { CustomNavigationClient } from "utils/NavigationClient";
import AppLayout from "components/AppLayout";
import Home from "components/Home";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <AppLayout>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </AppLayout>
    </MsalProvider>
  );
}

export default App;
