import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";

const UserInfo = (): JSX.Element => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || { name: "" });
  const [name, setName] = useState("");

  useEffect(() => {
    setName(account?.name || "");
  }, [account]);

  return <span className="text-pop-gray-500 pb-2">Logged, {name}</span>;
};

export default UserInfo;
