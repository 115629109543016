import { BUTTON, Button } from "@casox/platform-ui-components-library";
import { useMsal } from "@azure/msal-react";

const SignOutButton = () => {
  const { instance } = useMsal();
  const handleRedirectLogout = () => {
    instance.logoutRedirect();
  };
  return (
    <Button
      buttonType={BUTTON.EMPTY}
      onClick={handleRedirectLogout}
      className="w-[100px]"
    >
      Logout
    </Button>
  );
};

export default SignOutButton;
