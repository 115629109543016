import { Loading } from "@casox/platform-ui-components-library";
import { lazy, Suspense } from "react";

const LoadableCSVList = () => {
  const Component = lazy(
    () => import(/* webpackChunkName: 'gdpr-csv-list' */ "components/CSVList")
  );
  return (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  );
};

export default LoadableCSVList;
