import { BuildDate, BuildVersion } from "@casox/platform-ui-components-library";
import { getApplicationBuildDetails } from "app/settings";

const buildDetails = getApplicationBuildDetails();

export const BuildInfo = () => {
  return (
    <div className="text-center text-sm px-3">
      <div className="font-medium">
        <BuildVersion buildDetails={buildDetails} />
      </div>
      <div>
        <BuildDate buildDetails={buildDetails} />
      </div>
    </div>
  );
};
