import { Center } from "@casox/platform-ui-components-library";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import SignInButton from "components/SignInButton";
import LoadableCSVList from "components/CSVList/LoadableCSVList";

const Home = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <div className="h-[200px]">
          <Center>
            <SignInButton />
          </Center>
        </div>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <LoadableCSVList />
      </AuthenticatedTemplate>
    </>
  );
};
export default Home;
