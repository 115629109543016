import { useIsAuthenticated } from "@azure/msal-react";
import {
  ThreeColumns,
  PageHeader,
  Headline,
  Center,
} from "@casox/platform-ui-components-library";

import UserInfo from "components/UserInfo";
import SignOutButton from "components/SignOutButton";
import LogoP from "components/LogoP";

const AppHeader = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className="flex-0">
      <ThreeColumns
        left={
          <div className="pr-6 pt-[48px]">
            <LogoP />
          </div>
        }
        main={
          <PageHeader header={<Headline size={3}>GDPR Manager</Headline>} />
        }
        right={
          <>
            {isAuthenticated && (
              <Center>
                <div className="flex flex-col items-end">
                  <UserInfo />
                  <SignOutButton />
                </div>
              </Center>
            )}
          </>
        }
      ></ThreeColumns>
    </div>
  );
};

export default AppHeader;
