import { BuildDetailsData } from "@casox/platform-ui-components-library";

export const PROCESS_ENVS = {
  clientId: process.env.REACT_APP_AZURE_CLIENT_ID || "",
  authority: process.env.REACT_APP_AZURE_AUTHORITY || "",
  scopes: process.env.REACT_APP_AZURE_SCOPE || "",
};

export const getApplicationBuildDetails = (): BuildDetailsData => {
  return {
    version: process.env.REACT_APP_BUILD_VERSION || "",
    date: process.env.REACT_APP_BUILD_DATE || "",
    hash: process.env.REACT_APP_BUILD_HASH || "",
  };
};

export const MY_ADDRESS = window.location.origin;

export const API_ADDRESS = process.env.REACT_APP_GDPR_API || "";
