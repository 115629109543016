import { Tooltip } from "@casox/platform-ui-components-library";
import { BuildInfo } from "components/BuildInfo/BuildInfo";
import LogoPopcore from "components/LogoPopcore";

const AppFooter = () => {
  return (
    <div className="flex-0 py-4 text-pop-gray-500 flex">
      <div className="flex-1 text-sm mt-[2px]">© POPCORE GmbH | 2023</div>
      <div className="w-[100px] h-[10px] pt-[5px]">
        <Tooltip content={<BuildInfo />}>
          <LogoPopcore />
        </Tooltip>
      </div>
    </div>
  );
};

export default AppFooter;
